import React, { useState } from 'react';
import { t } from 'i18next';

const FAQ = () => {        
    const [active, setActive] = useState(1);

    const toggle = (id: number) => {
        if (active === id) {
            setActive(0);
        } else {
            setActive(id);
        }
    };

    return (
        <section className="py-12 bg-white sm:py-16 lg:py-20">
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">{t('faq.title')}</h2>
        </div>

        <div className="max-w-4xl mx-auto mt-8 sm:mt-14">
            <div  className="space-y-4">
                <div className="relative">
                    <div className="absolute -inset-1">
                        <div className="w-full h-full mx-auto opacity-30 blur-lg filter" style={{background: "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)"}}></div>
                    </div>

                    <div
                        onClick={() => toggle(1)}
                        role="region"
                        className="relative overflow-hidden bg-white border border-gray-200 rounded-xl"
                    >
                        <h3>
                            <button className="flex items-center justify-between w-full px-6 py-5 text-xl font-bold text-left text-gray-900 sm:p-8 font-pj">
                                <span> {t('faq.first-question')} </span>
                                {active === 1 ? (<span aria-hidden="true" className="ml-4">
                                    <svg className="w-6 h-6 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                                    </svg>
                                </span>): (
                                <span x-show="!expanded" aria-hidden="true" className="ml-4">
                                    <svg className="w-6 h-6 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                    </svg>
                                </span>)}
                            </button>
                        </h3>

                        {active === 1 && (
                            <div className="px-6 pb-6 sm:px-8 sm:pb-8">To use Professionalize It To Me to generate professional messages, simply visit our website and select the language you'd like your message to be in. Then, choose the type of message you'd like to generate and provide any additional information or prompts as needed. Our system will generate a professional message for you in just seconds.</div>
                        )}
                    </div>
                </div>

                <div className="relative">
                    <div
                        onClick={() => toggle(2)}
                        role="region"
                        className="relative overflow-hidden bg-white border border-gray-200 rounded-xl"
                    >
                        <h3>
                            <button className="flex items-center justify-between w-full px-6 py-5 text-xl font-bold text-left text-gray-900 sm:p-8 font-pj">
                                <span> {t('faq.second-question')} </span>
                                {active === 2 ? (<span aria-hidden="true" className="ml-4">
                                    <svg className="w-6 h-6 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                                    </svg>
                                </span>): (
                                <span x-show="!expanded" aria-hidden="true" className="ml-4">
                                    <svg className="w-6 h-6 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                    </svg>
                                </span>)}
                            </button>
                        </h3>

                        {active === 2 && (
                            <div className="px-6 pb-6 sm:px-8 sm:pb-8">{t('faq.second-answer')}</div>
                        )}
                    </div>
                </div>
                <div className="relative">
                    <div
                        onClick={() => toggle(3)}
                        role="region"
                        className="relative overflow-hidden bg-white border border-gray-200 rounded-xl"
                    >
                        <h3>
                            <button className="flex items-center justify-between w-full px-6 py-5 text-xl font-bold text-left text-gray-900 sm:p-8 font-pj">
                                <span>{ t('faq.third-question') } </span>
                                {active === 3 ? (<span aria-hidden="true" className="ml-4">
                                    <svg className="w-6 h-6 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                                    </svg>
                                </span>): (
                                <span x-show="!expanded" aria-hidden="true" className="ml-4">
                                    <svg className="w-6 h-6 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                    </svg>
                                </span>)}
                            </button>
                        </h3>

                        {active === 3 && (
                            <div className="px-6 pb-6 sm:px-8 sm:pb-8">{t('faq.third-answer')}</div>
                        )}
                    </div>
                </div>
                <div className="relative">
                    <div
                        onClick={() => toggle(4)}
                        role="region"
                        className="relative overflow-hidden bg-white border border-gray-200 rounded-xl"
                    >
                        <h3>
                            <button className="flex items-center justify-between w-full px-6 py-5 text-xl font-bold text-left text-gray-900 sm:p-8 font-pj">
                                <span> {t('faq.fourth-question')} </span>
                                {active === 4 ? (<span aria-hidden="true" className="ml-4">
                                    <svg className="w-6 h-6 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                                    </svg>
                                </span>): (
                                <span x-show="!expanded" aria-hidden="true" className="ml-4">
                                    <svg className="w-6 h-6 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                                    </svg>
                                </span>)}
                            </button>
                        </h3>

                        {active === 4 && (
                            <div className="px-6 pb-6 sm:px-8 sm:pb-8">{t('faq.fourth-answer')}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    )
}
export default FAQ;