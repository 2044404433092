import type { NextPage } from "next";
import Hero from "../components/Hero/Hero";
import mixpanel from "mixpanel-browser";
import { auth } from "../utils/Authentication";
import { useEffect, useState } from "react";
import { publicIpv4 } from "public-ip";
import { app } from "../initFirebase";
import PriceTable from "../components/Pricing/PriceTable";
import SegmentSelector from "../components/Hero/SegmentSelector";
import FAQ from "../components/Core/FAQ";

app;
const Home: NextPage = () => {
  const [ipAddress, setIpAddress] = useState("");
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY ?? "");

  const checkMixpanel = () => {
    if (auth.currentUser?.uid) {
      mixpanel.identify(auth.currentUser?.uid);

      mixpanel.people.set({
        $email: auth.currentUser?.email,
        $name: auth.currentUser?.displayName,
        $created: auth.currentUser?.metadata.creationTime,
        $last_login: auth.currentUser?.metadata.lastSignInTime,
      });
    } else {
      mixpanel.identify(ipAddress);
    }
  };

  const getIpAddress = async () => {
    const res = await publicIpv4();
    setIpAddress(res);
  };

  useEffect(() => {
    auth.onAuthStateChanged(() => {
      checkMixpanel();
    });
  }, []);

  return (
    <>
      <Hero />
      <PriceTable />
      <FAQ />
    </>
  );
};

export default Home;
